import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn } from "@angular/router";
import { PERMISSIONS_PROPERTY_NAME } from "../constants";
import { LoginService } from "../services";
import { UserPermissions } from "../enums";

export const permissionGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean => {
  const permissions = route.data[PERMISSIONS_PROPERTY_NAME] as Array<UserPermissions>;
  const loginService = inject(LoginService);
  return loginService.hasPermission(permissions);
};