<div aria-labelledby="user">
  <button mat-menu-item [matMenuTriggerFor]="requests" *ngIf="isUserRoleUser">Requests</button>
  <mat-menu #requests="matMenu">
    <button mat-menu-item routerLinkActive="active" routerLink="/user/my-requests">
      {{ 'NAV_BAR.MY_REQUESTS' | translate }}
    </button>
    <button mat-menu-item routerLinkActive="active" routerLink="/user/reviewed-requests">
      {{ 'NAV_BAR.REVIEWED_REQUESTS' | translate }}
    </button>
    <button
      mat-menu-item
      routerLinkActive="active"
      routerLink="/user/employee-leaves"
      *ngIf="isUserOperationsAdminUser"
    >
      {{ 'NAV_BAR.EMPLOYEE_LEAVES' | translate }}
    </button>
  </mat-menu>

  <button mat-menu-item [matMenuTriggerFor]="performanceMenu">
    {{ 'NAV_BAR.PERFORMANCE' | translate }}
  </button>
  <mat-menu #performanceMenu="matMenu">
    <button
      mat-menu-item
      routerLinkActive="active"
      routerLink="/user/performance/reviews"
    >
      {{ 'NAV_BAR.REVIEW_CYCLES' | translate }}
    </button>
  </mat-menu>

  <button mat-menu-item routerLinkActive="active" routerLink="/user/time-log">
    {{ 'NAV_BAR.TIME_LOG' | translate }}
  </button>
  <button
    mat-menu-item
    routerLinkActive="active"
    routerLink="/user/overtime"
    *ngIf="isUserRoleUser"
  >
    {{ 'NAV_BAR.OVERTIME' | translate }}
  </button>
  <button
    mat-menu-item
    routerLinkActive="active"
    routerLink="/user/travel-requests"
    *ngIf="isUserRoleUser"
  >
    {{ 'NAV_BAR.TRAVEL_REQUESTS' | translate }}
  </button>
  <button
    mat-menu-item
    routerLinkActive="active"
    routerLink="/user/reimbursement-requests"
    *ngIf="isUserRoleUser"
  >
    {{ 'NAV_BAR.SUBMIT_REIMBURSEMENT' | translate }}
  </button>
  <button mat-menu-item routerLinkActive="active" routerLink="/user/know-be" *ngIf="isUserRoleUser">
    {{ 'NAV_BAR.KNOWBE4' | translate }}
  </button>
  <button
    mat-menu-item
    routerLinkActive="active"
    routerLink="/user/schedule-job"
    *ngIf="isExclusiveUser"
  >
    {{ 'NAV_BAR.SCHEDULE_JOB' | translate }}
  </button>
  <button mat-menu-item routerLinkActive="active" routerLink="/user/my-settings">
    {{ 'NAV_BAR.SETTINGS' | translate }}
  </button>
  <button class="logout-button" mat-menu-item href="#" (click)="loginService.logOut()">
    {{ 'NAV_BAR.LOGOUT' | translate }}
  </button>
</div>
